import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogContent,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import TechnicianDashboardController, {
  Props,
  EventObjects,
} from "./TechnicianDashboardController.web";
// Customizable Area End

export default class TechnicianDashboard extends TechnicianDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getElementStyle = (status: string) => {
    if(status === "on going") {
      return webStyle.ongoing;
    } else if(status === "pending") {
      return webStyle.pending;
    } else {
      return webStyle.completed;
    }
  }

  renderEventContent = (timeText: string, title: string) => {
    return (
      <>
        <strong>{timeText}</strong>
        <i>{title}</i>
      </>
    );
  };

  renderTable = () => {
    return (
      <>
        <Box margin="35px 30px" sx={webStyle.mainWrapper}>
          <Box
            justifyContent="space-between"
            mb="40px"
            display={{ xs: "block", sm: "flex" }}
          >
            <Box>
              <Box sx={webStyle.mainTitle}>Technician Dashboard</Box>
              <Box color="#1b3c69" fontSize={{ xs: "16px", sm: "20px" }}>
                Table view
              </Box>
            </Box>
            <Box mt={{ sm: "0px", xs: "20px" }}>
              <Button
                data-test-id={"calendarBtn"}
                style={webStyle.buttonStyle}
                onClick={this.toggleState}
              >
                Calendar
              </Button>
              <Button
                style={webStyle.buttonStyle}
                data-test-id={"logoutBtn"}
                onClick={this.handleLogout}
              >
                Log out
              </Button>
            </Box>
          </Box>
          <Box mt="60px">
            <Box>
              <Grid container>
                <Grid item xs={12} style={{ overflow: "auto" }}>
                  <Table
                    style={{
                      borderCollapse: "separate",
                      border: "1px solid #E0E0E0",
                      borderRadius: "10px",
                    }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={webStyle.tableData}>Name</TableCell>
                        <TableCell align="left" style={webStyle.tableData}>Description</TableCell>
                        <TableCell align="left" style={webStyle.tableData}>Date</TableCell>
                        <TableCell align="left" style={webStyle.tableData}>Supervisor</TableCell>
                        <TableCell align="left" style={webStyle.tableData}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.eventObject.length > 0 ? (
                        <>
                          {this.state.eventObject.map(
                            (item: EventObjects, index: number) => {
                              let styleVar = this.getElementStyle(item.status.toLowerCase());
                              return (
                                <TableRow key={`${index + 1}`}>
                                  <TableCell
                                    style={webStyle.tableData}
                                    align="left"
                                  >
                                    {item.title}
                                  </TableCell>
                                  <TableCell
                                    style={webStyle.tableData}
                                    align="left"
                                  >
                                    {item.description}
                                  </TableCell>
                                  <TableCell
                                    style={webStyle.tableData}
                                    align="left"
                                  >
                                    {item.date.slice(0, 10)}
                                  </TableCell>

                                  <TableCell
                                    style={webStyle.tableData}
                                    align="left"
                                  >
                                    {item.supervisor}
                                  </TableCell>
                                  <TableCell
                                    style={webStyle.statusColumn}
                                    align="center"
                                  >
                                    <div style={styleVar}>{item.status}</div>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell
                            style={webStyle.noRecord}
                            align="center"
                            colSpan={8}
                          >
                            No records found!
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  renderCalender = () => {
    return (
      <>
        <Box margin="35px 30px" sx={webStyle.mainWrapper}>
          <Box
            display={{ xs: "block", sm: "flex" }}
            mb="40px"
            justifyContent="space-between"
          >
            <Box>
              <Box sx={webStyle.mainTitle}>Technician Dashboard</Box>
              <Box fontSize={{ xs: "16px", sm: "20px" }} color="#1b3c69">
                Task details
              </Box>
            </Box>
            <Box mt={{ xs: "20px", sm: "0px" }}>
              <Button
                data-test-id={"dashboardBtn"}
                style={webStyle.buttonStyle}
                onClick={this.toggleState}
              >
                Dashboard
              </Button>
              <Button
                onClick={this.handleLogout}
                data-test-id={"logoutBtn"}
                style={webStyle.buttonStyle}
              >
                Log out
              </Button>
            </Box>
          </Box>
          <div>
            <FullCalendar
              initialView="dayGridMonth"
              plugins={[dayGridPlugin]}
              data-test-id={"fullcalendarElement"}
              eventContent={(eventInfo) =>
                this.renderEventContent(
                  eventInfo.timeText,
                  eventInfo.event.title
                  )
                }
              eventClick={(event) =>
                this.handleEventClick(
                  event.event.title,
                  event.event.startStr,
                  event.event.extendedProps.task_id,
                  event.event.extendedProps.status,
                  event.event.extendedProps.description,
                  event.event.extendedProps.supervisor,
                  event.event.extendedProps.task_date_status_id
                )
              }
              events={this.state.eventObject}
              displayEventTime={false}
            />
          </div>
          <Dialog
            open={this.state.openDialogName === "Add"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            transitionDuration={0}
            scroll="body"
            maxWidth={"md"}
          >
            <DialogContent style={{ padding: "0px", width: "400px" }}>
              <Box sx={webStyle.modalHeader}>
                <Box id="modal-title" sx={webStyle.headerStyle}>
                  {this.state.eventTitle}
                </Box>
                <CloseIcon
                  id="closeBtn"
                  onClick={this.onCancel}
                  style={webStyle.closeButton}
                />
              </Box>
              <Box sx={webStyle.modalBody}>
                <div style={webStyle.row}>
                  <span style={webStyle.label}>Description: </span>{" "}{this.state.eventDescription}
                </div>
                <div style={webStyle.row}>
                  <span style={webStyle.label}>Supervisor: </span>{" "}{this.state.eventSupervisor}
                </div>
                <div style={webStyle.row}>
                  <span style={webStyle.label}>Current Status: </span>{" "}{this.state.eventStatus}
                </div>
                <Box sx={webStyle.buttonWrapper}>
                  {this.state.eventStatus.toLowerCase() === "pending" && (
                    <AcceptButton
                      id="pending"
                      disabled={
                        this.state.eventDate > new Date().toISOString().slice(0, 10)
                      }
                      onClick={() => {this.changeTaskStatus()}}
                    >
                      Accept
                    </AcceptButton>
                  )}
                  {this.state.eventStatus.toLowerCase() === "on going" && (
                    <CompleteButton
                      disabled={
                        this.state.eventDate > new Date().toISOString().slice(0, 10)
                      }
                      onClick={() =>
                        this.changeStatusToCompleted()
                      }
                      id="completed"
                    >
                      Complete
                    </CompleteButton>
                  )}
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </>
    );
  };

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <div className="Toastify"></div>
        {this.state.toggle ? this.renderCalender() : this.renderTable()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AcceptButton = styled(Button)({
  color: "rgba(21,135,247, 1)",
  width: "100%",
  border: "1px solid rgba(21,135,247, 1)",
  padding: "7px 21px",
  backgroundColor: "rgba(21,135,247, 0.2)",
  borderRadius: "10px",
  textTransform: "none",
  cursor: "pointer",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: "rgba(21,135,247, 0.5)",
  },
});

const CompleteButton = styled(Button)({
  color: "rgba(14,146,69,1)",
  textTransform: "none",
  padding: "7px 21px",
  fontSize: "16px",
  backgroundColor: "rgba(14,146,69,0.2)",
  borderRadius: "10px",
  cursor: "pointer",
  width: "100%",
  border: "1px solid rgba(14,146,69,1)",
  "&:hover": {
    backgroundColor: "rgba(14,146,69,0.5)",
  },
});

const webStyle = {
  label: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#000",
  },
  closeButton: {
    cursor: "pointer",
    fontSize: "25px",
    color: "#787878",
  },
  modalHeader: {
    display: "flex",
    padding: "15px 20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerStyle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "bolder",
    fontSize: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 0px",
  },
  modalBody: {
    padding: "10px 20px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  row: {
    marginBottom: "5px",
  },
  tableData: {
    color: "#000",
    fontSize: "16px",
  },
  statusColumn: {
    display: "flex",
    fontSize: "16px",
    justifyContent: "center",
    alignItems: "center",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    fontSize: "30px",
    color: "#1b3c69",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pending: {
    backgroundColor: "rgba(247,198,0, 0.2)",
    color: "rgba(247,198,0, 1)",
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "20px",
    border: "1px solid rgba(247,198,0, 1)",
    width: "90px",
  },
  ongoing: {
    backgroundColor: "rgba(21,135,247, 0.2)",
    border: "1px solid rgba(21,135,247, 1)",
    width: "90px",
    padding: "5px 10px",
    color: "rgba(21,135,247, 1)",
    margin: "5px",
    borderRadius: "20px",
  },
  completed: {
    backgroundColor: "rgba(14,146,69,0.2)",
    color: "rgba(14,146,69,1)",
    padding: "5px 10px",
    borderRadius: "20px",
    margin: "5px",
    width: "90px",
    border: "1px solid rgba(14,146,69,1)",
  },
  buttonStyle: {
    backgroundColor: "rgb(98, 0, 238)",
    border: "none",
    padding: "10px 25px",
    marginLeft: "15px",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End

