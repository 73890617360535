// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
// import firebase from 'firebase'
// import { connect } from 'react-firebase'
import { getFirebaseToken, onMessageListener, displayToast } from "./firebase.js";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import Portal from '@material-ui/core/Portal';
import { ToastContainer, toast } from 'react-toastify';
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfwhatsappbusinessapi from "../../blocks/cfwhatsappbusinessapi/src/Cfwhatsappbusinessapi";
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Cfdashboard4 from "../../blocks/cfdashboard4/src/Cfdashboard4";
import Timetracker2 from "../../blocks/timetracker2/src/Timetracker2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Hamburgermenu from "../../blocks/hamburgermenu/src/Hamburgermenu";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Docusignintegration from "../../blocks/docusignintegration/src/Docusignintegration";
import DigitalSignatures from "../../blocks/digitalsignatures/src/DigitalSignatures";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Prioritise from "../../blocks/prioritise/src/Prioritise";
import Elasticsearch2 from "../../blocks/elasticsearch2/src/Elasticsearch2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Cfdashboard5 from "../../blocks/cfdashboard5/src/Cfdashboard5";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Cfapiintegrationtallyerp from "../../blocks/cfapiintegrationtallyerp/src/Cfapiintegrationtallyerp";
// import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfdashboard3 from "../../blocks/cfdashboard3/src/Cfdashboard3";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Download from "../../blocks/download/src/Download";
import Location from "../../blocks/location/src/Location";
import Geofence from "../../blocks/geofence/src/Geofence";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Cfautomaticaccountdeletion from "../../blocks/cfautomaticaccountdeletion/src/Cfautomaticaccountdeletion";
import Inapppurchasing from "../../blocks/inapppurchasing/src/Inapppurchasing";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Dashboardguests from "../../blocks/dashboardguests/src/Dashboardguests";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Repeatingtasks from "../../blocks/repeatingtasks/src/Repeatingtasks.web";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Kanbanboard2 from "../../blocks/kanbanboard2/src/Kanbanboard2";
import Projectreporting from "../../blocks/projectreporting/src/Projectreporting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Receiptcustomisation from "../../blocks/receiptcustomisation/src/Receiptcustomisation";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Inventorymanagement2 from "../../blocks/inventorymanagement2/src/Inventorymanagement2";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
// import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Automatedprioritisation from "../../blocks/automatedprioritisation/src/Automatedprioritisation";
import Timesheetmanagement2 from "../../blocks/timesheetmanagement2/src/Timesheetmanagement2";
import Maps from "../../blocks/maps/src/Maps";
import TimeTracker from "../../blocks/timetracker/src/TimeTracker";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import Docusignintegration2 from "../../blocks/docusignintegration2/src/Docusignintegration2";
import Receiptcustomisation2 from "../../blocks/receiptcustomisation2/src/Receiptcustomisation2";
import Repeatingtasks2 from "../../blocks/repeatingtasks2/src/Repeatingtasks2";
import Automatedprioritisation2 from "../../blocks/automatedprioritisation2/src/Automatedprioritisation2";
import Customform4 from "../../blocks/customform4/src/Customform4";
import Login from "../../blocks/automatedprioritisation/src/Login.web";
import LoginNew from "../../blocks/automaticreminders/src/Login.web";
import Prioritisation from "../../blocks/automaticreminders/src/Automatedprioritisation.web";
import TechnicianDashboard from "../../blocks/automatedprioritisation/src/TechnicianDashboard.web";
import Customform from "../../blocks/customform/src/CustomForm.web";
import 'react-toastify/dist/ReactToastify.css';





const routeMap = {
Prioritisation:{
  component:Prioritisation,
path:"/Prioritisation"},
Login:{
  component:Login,
path:"/Login"},
LoginNew:{
  component:LoginNew,
path:"/login-new"},
TechnicianDashboard:{
  component: TechnicianDashboard,
path:"/TechnicianDashboard"},
Customform:{
  component:Customform,
  path:"/ManageUser"},
Docusignintegration2:{
 component:Docusignintegration2,
path:"/Docusignintegration2"},
Receiptcustomisation2:{
 component:Receiptcustomisation2,
path:"/Receiptcustomisation2"},
Repeatingtasks2:{
 component:Repeatingtasks2,
path:"/Repeatingtasks2"},
Automatedprioritisation2:{
 component:Automatedprioritisation2,
path:"/Automatedprioritisation2"},
Customform4:{
 component:Customform4,
path:"/Customform4"},



TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},

Cfwhatsappbusinessapi:{
 component:Cfwhatsappbusinessapi,
path:"/Cfwhatsappbusinessapi"},
Customisableuserprofiles2:{
 component:Customisableuserprofiles2,
path:"/Customisableuserprofiles2"},
Cfdashboard4:{
 component:Cfdashboard4,
path:"/Cfdashboard4"},
Timetracker2:{
 component:Timetracker2,
path:"/Timetracker2"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Hamburgermenu:{
 component:Hamburgermenu,
path:"/Hamburgermenu"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Docusignintegration:{
 component:Docusignintegration,
path:"/Docusignintegration"},
DigitalSignatures:{
 component:DigitalSignatures,
path:"/DigitalSignatures"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Prioritise:{
 component:Prioritise,
path:"/Prioritise"},
Elasticsearch2:{
 component:Elasticsearch2,
path:"/Elasticsearch2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Cfdashboard5:{
 component:Cfdashboard5,
path:"/Cfdashboard5"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
Cfapiintegrationtallyerp:{
 component:Cfapiintegrationtallyerp,
path:"/Cfapiintegrationtallyerp"},
// CameraAccess:{
//  component:CameraAccess,
// path:"/CameraAccess"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfdashboard3:{
 component:Cfdashboard3,
path:"/Cfdashboard3"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Download:{
 component:Download,
path:"/Download"},
Location:{
 component:Location,
path:"/Location"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Cfautomaticaccountdeletion:{
 component:Cfautomaticaccountdeletion,
path:"/Cfautomaticaccountdeletion"},
Inapppurchasing:{
 component:Inapppurchasing,
path:"/Inapppurchasing"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Dashboardguests:{
 component:Dashboardguests,
path:"/Dashboardguests"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Repeatingtasks:{
 component:Repeatingtasks,
path:"/Repeatingtasks"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Kanbanboard2:{
 component:Kanbanboard2,
path:"/Kanbanboard2"},
Projectreporting:{
 component:Projectreporting,
path:"/Projectreporting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Automaticreminders:{
 component:Automaticreminders,
path:"/Automaticreminders"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Receiptcustomisation:{
 component:Receiptcustomisation,
path:"/Receiptcustomisation"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Inventorymanagement2:{
 component:Inventorymanagement2,
path:"/Inventorymanagement2"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
// Analytics:{
//  component:Analytics,
// path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Automatedprioritisation:{
 component:Automatedprioritisation,
path:"/SupervisorDashboard"},
Timesheetmanagement2:{
 component:Timesheetmanagement2,
path:"/Timesheetmanagement2"},
Maps:{
 component:Maps,
path:"/Maps"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false
    }
  }

  getToken = () => {
    (async () => {
      try {
        const res = await getFirebaseToken();
        console.log(res);
        localStorage.setItem("fcm", res);
      } catch (e) {
        console.log(e);
      }
    })();
  }

  render() {
    if(window.location.pathname === "/login-new" && !localStorage.getItem("fcm")){
      this.getToken();
    }
    onMessageListener()
      .then((data) => {
        this.setState({ flag: !this.state.flag });
        toast(displayToast(data?.notification?.title, data?.notification?.body));
      })
      .catch((err) => console.log("failed: ", err));

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <Portal>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Portal>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;