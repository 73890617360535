import React from "react";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyALJ4Qt7NWheBlxyJ28Ik7vlapkWXDkebU",
  authDomain: "stratafm-f7878.firebaseapp.com",
  projectId: "stratafm-f7878",
  storageBucket: "stratafm-f7878.appspot.com",
  messagingSenderId: "399595205765",
  appId: "1:399595205765:web:d97d814becdae5d529e5ec",
  measurementId: "G-F8J235BE6G",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const getFirebaseToken = async () => {
  try {
    const res = await getToken(messaging, {
      vapidKey:
        "BMpgh8XySRBFGCj66O_8WIpqKi9n80QejrwndSTkMGWiU40JIKBeom9_to47yusZUOYMEHTPvJcSCR3H7jQNle4",
    });
    return res;
  } catch (e) {
    console.log(e);
    return "";
  }
};

const displayToast = (title, body) => {
  return (
    <div>
     <strong >{title}</strong>
     <div style={{"paddingTop": "10px"}}>{body}</div>
    </div>
   )
};
export { firebaseApp, getFirebaseToken, onMessageListener, displayToast };
