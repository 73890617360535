import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TimeTrackerController, {
  Props,
  config,
} from "./TimeTrackerController.web";

export default class TimeTracker extends TimeTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { remainingTime } = this.state;
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{config.labelTitleText}</Typography>
            {!this.state.startTime ? (
              <>
                <Box sx={webStyle.textInputMainView}>
                  <Grid container>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box>MM</Box>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box>SS</Box>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box sx={webStyle.minuteInput}>
                        <Input
                          inputProps={{ style: { fontSize: 30, textAlign: 'center' } }}
                          data-test-id={"txtInputMin"}
                          value={this.state.minute}
                          onChange={(event) => this.minuteSet(event.target.value)}
                          disableUnderline={true}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box sx={webStyle.minuteInput}>
                        <Input
                          inputProps={{ style: { fontSize: 30, textAlign: 'center' } }}
                          type="text"
                          data-test-id={"txtInputSec"}
                          value={this.state.second}
                          onChange={(event) => this.secondSet(event.target.value)}
                          disableUnderline={true}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <Box sx={webStyle.textInputMainView}>
                <Box sx={webStyle.timerView}>
                  <Box sx={webStyle.minuteText}>{`${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`}</Box>
                </Box>
              </Box>
            )}
            {!this.state.startTime ? (<Box
              data-test-id="BtnStart"
              onClick={this.startCount}
              component="button"
              sx={webStyle.startBtn}
            >
              <Button disabled={this.state.minute == 0 && this.state.second == 0 ? true : false}>Start</Button>
            </Box>) : (
              <Box sx={webStyle.endBtnView}>
                <Box data-test-id="BtnStop" component="button" sx={webStyle.stopBtn} onClick={this.stopBtn}>
                  <Button >Stop</Button>
                </Box>
                <Box data-test-id="BtnReset" component="button" sx={webStyle.resetBtn} onClick={this.resetBtn}>
                  <Button >Reset</Button>
                </Box>
              </Box>)}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    marginTop: '20px'
  },
  container: {
    flex: 1,
    backgroundColor: "#ffffffff",
  },
  headingText: {
    marginTop: 100,
    fontSize: 25,
    textAlign: "center",
    fontWeight: "500",
    color: "#000000",
  },
  timerView: {
    display: 'flex',
    width: 200,
    height: 50,
    fontSize: 25,
    color: "#000000",
    fontWeight: "200",
    paddingHorizontal: 4,
    marginHorizontal: 5,
    textAlign: "center",
    borderColor: "#000000",
    borderRadius: '8px',
    border: '1px solid',
    justifyContent: "center",
    padding: '10px'
  },

  textInputMainView: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
  },
  minuteInput: {
    width: 50,
    height: 50,
    fontSize: 25,
    color: "#000000",
    fontWeight: "200",
    paddingHorizontal: 4,
    marginHorizontal: 5,
    textAlign: "center",
    borderColor: "#000000",
    borderRadius: '8px',
    border: '1px solid',

  },
  minuteText: {
    width: 50,
    height: 50,
    fontSize: 27,
    fontWeight: "300",
    color: "#000000",
    paddingHorizontal: 4,
    marginHorizontal: 5,
    textAlign: "center",
    borderColor: "#000000",
    borderRadius: 8,
    borderWidth: 1,
    display: 'contents'
  },

  startBtn: {
    marginTop: 20,
    backgroundColor: "#3aad36",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 190,
    alignSelf: "center",
    borderColor: "#000000",
    borderRadius: 8,
    borderWidth: 1,
    color: 'black',
  },
  startText: {
    fontSize: 23,
  },
  endBtnView: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  stopBtn: {
    marginTop: 20,
    backgroundColor: "#f23535",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 90,
    alignSelf: "center",
    borderColor: "#000000",
    borderRadius: 8,
    borderWidth: 1,
    margin: '10px',
    textAlign: 'center'

  },
  resetBtn: {
    marginTop: 20,
    backgroundColor: "#3aad36",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 90,
    alignSelf: "center",
    borderColor: "#000000",
    borderRadius: 8,
    borderWidth: 1,
    margin: '10px',
    textAlign: 'center'
  },
};
// Customizable Area End