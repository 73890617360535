import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface ResponseJson {
  id: string;
  type: string;
  attributes: Data;
}

export interface EventObjects {
  title: string;
  color: string;
  date: string;
  status: string;
  task_id: number;
  supervisor: string;
  description: string;
  task_date_status_id: number;
}

export interface Data {
  id: number;
  description: string;
  frequency: string;
  title: string;
  start_date: string;
  end_date: string;
  created_at: string;
  supervisor: string;
  technician: string;
  task_date_statuses: DateStatusData;
  updated_at: string;
}

export interface DateStatusData {
  data: InnerStatus[];
}
export interface InnerStatus {
  id: string;
  attributes: DateAttributes;
  type: string;
}

export interface DateAttributes {
  id: number;
  status: string;
  task_id: number;
  date: string;
  updated_at: string;
  created_at: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  toggle: boolean;
  data: ResponseJson[];
  title: string;
  category: string;
  subCategory: string;
  startDate: string;
  description: string;
  endDate: string;
  technician: string;
  frequency: string;
  openDialogName: string;
  eventTitle: string;
  eventDescription: string;
  eventDate: string;
  eventStatus: string;
  eventId: number;
  eventSupervisor: string;
  eventObject: EventObjects[];
  eventTaskDateStatusId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class TechnicianDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changeTaskStatusApiCallId: string = "";
  getTaskListApiCallId: string = "";
  changeTaskApiCallId: string = "";
  completedTaskApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      toggle: true,
      data: [],
      title: "",
      category: "",
      subCategory: "",
      startDate: "",
      eventStatus: "",
      endDate: "",
      technician: "",
      frequency: "",
      eventObject: [],
      description: "",
      openDialogName: "",
      eventSupervisor: "",
      eventTitle: "",
      eventDate: "",
      eventTaskDateStatusId: 0,
      eventId: 0,
      eventDescription: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (apiRequestCallId == this.getTaskListApiCallId) {
          let nullArray: EventObjects[] = [];
          responseJson.data.length > 0 &&
          responseJson.data.map((item: ResponseJson) =>
                item.attributes.task_date_statuses.data.length > 0 && item.attributes.task_date_statuses.data.map(
                  (dateObject: InnerStatus) => {
                    nullArray.push({
                      title: item.attributes.title,
                      task_id: item.attributes.id,
                      date: dateObject.attributes.date.slice(0, 10),
                      task_date_status_id: dateObject.attributes.id,
                      color: this.getElementColor(dateObject.attributes.status.toLowerCase()),
                      status: dateObject.attributes.status.replace("_", " ").charAt(0).toUpperCase() + dateObject.attributes.status.replace("_", " ").slice(1),
                      supervisor: item.attributes.supervisor,
                      description: item.attributes.description,
                    });
                  }
                )
            );
          this.setState({ eventObject: nullArray });
        } else if (apiRequestCallId == this.completedTaskApiCallId) {
          this.setState({ openDialogName: "" });
          toast.success("Task is completed!");
          this.getTaskList();
        } else if (apiRequestCallId == this.changeTaskApiCallId) {
          toast.success("Task is started!");
          this.setState({ openDialogName: "" });
          this.getTaskList();
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getElementColor = (status: string) => {
    if (status === "on_going") {
      return "#1088f7";
    } else if (status === "pending") {
      return "#f76d01";
    } else {
      return "#189c52";
    }
  }

  async componentDidMount() {
    this.getTaskList();
  }

  getTaskList = () => {
    const header = {
      "Content-Type": configJSON.getTechnicianTaskListApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTechnicianTaskListApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTechnicianTaskListApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changeStatusToCompleted = () => {
    const header = {
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completedTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.completedTaskApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.completedTaskEndPoint}?id=${this.state.eventTaskDateStatusId}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changeTaskStatus = () => {
    const header = {
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.changeTaskApiEndPoint}?id=${this.state.eventTaskDateStatusId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.changeTaskApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onCancel = () => {
    this.setState({
      openDialogName: "",
    });
  };

  handleLogout = () => {
    localStorage.removeItem("accessToken");
    this.props.navigation.navigate("Login");
  };

  handleEventClick = (
    eventTitle: string,
    eventDate: string,
    eventId: number,
    eventStatus: string,
    eventDescription: string,
    eventSupervisor: string,
    eventTaskDateStatusId: number) => {
    this.setState({
      eventStatus,
      eventDate,
      eventTitle,
      eventId,
      eventTaskDateStatusId,
      eventDescription,
      eventSupervisor,
      openDialogName: "Add",
    });
  };

  toggleState = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  // Customizable Area End
}
