import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  DialogContent,
  Button,
  Dialog,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import CloseIcon from "@material-ui/icons/Close";
import AutomaticremindersController, {
  Props,
  EventObjects,
} from "./AutomaticremindersController.web";
// Customizable Area End

export default class Automaticreminders extends AutomaticremindersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTable = () => {
    return (
      <>
        <Box margin="35px 30px" sx={webStyle.mainWrapper}>
          <Box
            justifyContent="space-between"
            mb="40px"
            display={{ xs: "block", sm: "flex" }}
          >
            <Box>
              <Box sx={webStyle.mainTitle}>Technician Dashboard</Box>
              <Box color="#1b3c69" fontSize={{ xs: "16px", sm: "20px" }}>Table view</Box>
            </Box>
            <Box mt={{ sm: "0px", xs: "20px" }}>
              <Button
                data-test-id={"calendarBtn"}
                onClick={this.toggleState}
                style={webStyle.buttonStyle}
              >
                Calendar
              </Button>
              <Button
                onClick={this.handleLogout}
                data-test-id={"logoutBtn"}
                style={webStyle.buttonStyle}
              >
                Log out
              </Button>
            </Box>
          </Box>
          <Box mt="60px">
            <Box>
              <Grid container>
                <Grid item xs={12}
                  style={{ overflow: "auto" }}
                >
                  <Table
                    aria-label="simple table"
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #E0E0E0",
                      borderCollapse: "separate",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={webStyle.tableData} align="left">
                          Name
                        </TableCell>
                        <TableCell style={webStyle.tableData} align="left">
                          Description
                        </TableCell>
                        <TableCell style={webStyle.tableData} align="left">
                          Date
                        </TableCell>
                        <TableCell style={webStyle.tableData} align="left">
                          Supervisor
                        </TableCell>
                        <TableCell style={webStyle.tableData} align="center">
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.eventObject.length > 0 ?
                        (<>{this.state.eventObject.map(
                          (item: EventObjects, index: number) => {
                            let styleVar = this.getElementStyle(item.status.toLowerCase());
                            return (
                              <TableRow key={`${index + 1}`}>
                                <TableCell style={webStyle.tableData} align="left">
                                  {item.title}
                                </TableCell>
                                <TableCell align="left" style={webStyle.tableData}>
                                  {item.description}
                                </TableCell>
                                <TableCell style={webStyle.tableData} align="left">
                                  {item.date.slice(0, 10)}
                                </TableCell>
                                <TableCell style={webStyle.tableData} align="left">
                                  {item.supervisor}
                                </TableCell>
                                <TableCell style={webStyle.statusColumn} align="center">
                                  <div style={styleVar}>{item.status}</div>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                        </>
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={8}
                              style={webStyle.noRecord}
                            >
                              No records found!
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  renderEventContent = (timeText: string, title: string) => {
    return (
      <>
        <strong>{timeText}</strong>
        <i>{title}</i>
      </>
    );
  };

  renderCalender = () => {
    return (
      <>
        <Box sx={webStyle.mainWrapper} margin="35px 30px">
          <Box
            justifyContent="space-between"
            display={{ xs: "block", sm: "flex" }}
            mb="40px"
          >
            <Box>
              <Box sx={webStyle.mainTitle}>Technician Dashboard</Box>
              <Box color="#1b3c69" fontSize={{ xs: "16px", sm: "20px" }}>
                Task details
              </Box>
            </Box>
            <Box mt={{ xs: "20px", sm: "0px" }}>
              <Button
                style={webStyle.buttonStyle}
                onClick={this.toggleState}
                data-test-id={"dashboardBtn"}
              >
                Dashboard
              </Button>
              <Button
                onClick={this.handleLogout}
                data-test-id={"logoutBtn"}
                style={webStyle.buttonStyle}
              >
                Log out
              </Button>
            </Box>
          </Box>
          <div>
            <FullCalendar
              plugins={[dayGridPlugin]}
              data-test-id={"fullcalendarElement"}
              initialView="dayGridMonth"
              eventContent={(eventInfo) =>
                this.renderEventContent(
                  eventInfo.timeText,
                  eventInfo.event.title
                )
              }
              events={this.state.eventObject}
              eventClick={(event) =>
                this.handleEventClick(
                  event.event.title,
                  event.event.startStr,
                  event.event.extendedProps.status,
                  event.event.extendedProps.task_id,
                  event.event.extendedProps.description,
                  event.event.extendedProps.task_date_status_id,
                  event.event.extendedProps.supervisor,
                )
              }
              displayEventTime={false}
            />
          </div>
          <Dialog
            maxWidth={"md"}
            aria-describedby="scroll-dialog-description"
            transitionDuration={0}
            open={this.state.openDialogName === "Add"}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
          >
            <DialogContent style={{ padding: "0px", width: "400px" }}>
              <Box sx={webStyle.modalHeader}>
                <Box sx={webStyle.headerStyle} id="modal-title">
                  {this.state.eventTitle}
                </Box>
                <CloseIcon
                  id="closeBtn"
                  onClick={this.onCancel}
                  style={webStyle.closeButton}
                />
              </Box>
              <Box sx={webStyle.modalBody}>
                <div
                  style={webStyle.row}
                >
                  <span style={webStyle.label}>Description: </span>{" "}
                  {this.state.eventDescription}
                </div>
                <div
                  style={webStyle.row}
                >
                  <span style={webStyle.label}>Supervisor: </span>{" "}
                  {this.state.eventSupervisor}
                </div>
                <div
                  style={webStyle.row}
                >
                  <span style={webStyle.label}>Current Status: </span>{" "}
                  {this.state.eventStatus}
                </div>
                <Box sx={webStyle.buttonWrapper}>
                  {this.state.eventStatus.toLowerCase() === "pending" && (
                    <AcceptButton
                      id="pending"
                      disabled={
                        this.state.eventDate >
                        new Date().toISOString().slice(0, 10)
                      }
                      onClick={() => { this.changeTaskStatus() }}
                    >
                      Accept
                    </AcceptButton>
                  )}
                  {this.state.eventStatus.toLowerCase() === "on going" && (
                    <CompleteButton
                      id="completed"
                      onClick={() =>
                        this.changeStatusToCompleted()
                      }
                      disabled={
                        this.state.eventDate > new Date().toISOString().slice(0, 10)
                      }
                    >
                      Complete
                    </CompleteButton>
                  )}
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </>
    );
  };

  getElementStyle = (status: string) => {
    if (status === "pending") {
      return webStyle.pending;
    } else if (status === "on going") {
      return webStyle.ongoing;
    } else {
      return webStyle.completed;
    }
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <div className="Toastify"></div>
        {this.state.toggle ? this.renderCalender() : this.renderTable()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CompleteButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "rgba(14,146,69,0.2)",
  color: "rgba(14,146,69,1)",
  border: "1px solid rgba(14,146,69,1)",
  fontSize: "16px",
  padding: "7px 21px",
  width: "100%",
  borderRadius: "10px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(14,146,69,0.5)",
  },
});

const AcceptButton = styled(Button)({
  color: "rgba(21,135,247, 1)",
  border: "1px solid rgba(21,135,247, 1)",
  cursor: "pointer",
  fontSize: "16px",
  borderRadius: "10px",
  textTransform: "none",
  width: "100%",
  padding: "7px 21px",
  backgroundColor: "rgba(21,135,247, 0.2)",
  "&:hover": {
    backgroundColor: "rgba(21,135,247, 0.5)",
  },
});

const webStyle = {
  label: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#000",
  },
  closeButton: {
    cursor: "pointer",
    color: "#787878",
    fontSize: "25px",
  },
  modalBody: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: "10px 20px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 20px",
  },
  
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "15px 0px",
    alignItems: "center",
  },
  row: {
    marginBottom: "5px",
  },
  headerStyle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "20px",
    fontWeight: "bolder",
  },
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    justifyContent: "center",
    display: "flex",
    fontSize: "16px",
    color: "#000",
    alignItems: "center",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    fontSize: "30px",
    marginBottom: "5px",
    color: "#1b3c69",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pending: {
    padding: "5px 10px",
    backgroundColor: "rgba(247,198,0, 0.2)",
    color: "rgba(247,198,0, 1)",
    width: "90px",
    margin: "5px",
    borderRadius: "20px",
    border: "1px solid rgba(247,198,0, 1)",
  },
  ongoing: {
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "20px",
    backgroundColor: "rgba(21,135,247, 0.2)",
    color: "rgba(21,135,247, 1)",
    border: "1px solid rgba(21,135,247, 1)",
    width: "90px",
  },
  completed: {
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "20px",
    backgroundColor: "rgba(14,146,69,0.2)",
    color: "rgba(14,146,69,1)",
    border: "1px solid rgba(14,146,69,1)",
    width: "90px",
  },
  buttonStyle: {
    border: "none",
    padding: "10px 25px",
    cursor: "pointer",
    backgroundColor: "rgb(98, 0, 238)",
    marginLeft: "15px",
    color: "white",
  }
};
// Customizable Area End
