import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import LoginController, { Props } from "./LoginController.web";
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className="Toastify"></div>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Formik
              initialValues={{
                password: "",
                type: "Technician",
                email: "",
              }}
              data-test-id="formik"
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email format")
                  .required("Email is required"),
                type: Yup.string().required("Type is required"),
                password: Yup.string()
                  .nullable()
                  .required("Password is required"),
              })}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={() => {
                this.handleLogin();
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Box sx={webStyle.formWrapper}>
                    <Typography variant="h6" align="center">
                      Login
                    </Typography>
                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Email</InputLabel>
                      <CustomInputBase
                        data-test-id={"email"}
                        name="email"
                        id="email"
                        value={props.values.email}
                        onChange={(event) => {
                          props.setFieldValue("email", event.target.value);
                          this.onValueChange("email", event.target.value);
                        }}
                        fullWidth={true}
                        variant="outlined"
                        type="text"
                        onBlur={props.handleBlur}
                        placeholder="Enter Email"
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "email"
                      )}
                    </Box>
                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Password</InputLabel>
                      <CustomInputBase
                        id="password"
                        data-test-id={"password"}
                        value={props.values.password}
                        onChange={(event) => {
                          props.setFieldValue("password", event.target.value);
                          this.onValueChange("password", event.target.value);
                        }}
                        name="password"
                        onBlur={props.handleBlur}
                        variant="outlined"
                        placeholder="Enter Password"
                        fullWidth={true}
                        type={"password"}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "password"
                      )}
                    </Box>
                    <Button
                      type="submit"
                      style={webStyle.buttonStyle}
                      data-test-id="LoginBtn"
                    >
                      Log in
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomInputBase = styled(TextField)({
  minHeight: "50px",
  borderRadius: "5px",
  marginTop: "8px",
  marginBottom: "12px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
    padding: "15px",
  },
  "& .Mui-focused": {
    border: "1px solid #d6d6d6",
  },
  "& input": {
    fontSize: "14px",
  },
});

const webStyle = {
  inputWrapper: {
    position: "relative",
  },
  mainWrapper: {
    paddingBottom: "30px",
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    justifyContent: "center",
    height: "100vh",
  },
  buttonStyle: {
    height: "45px",
    width: "100%",
    marginTop: "25px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
  label: {
    fontSize: "14px",
    fontWeight: 300,
    marginTop: "8px",
  },
  formWrapper: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    padding: "20px",
    width: "100%",
  },
};
// Customizable Area End
