
import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Select, { ValueType } from "react-select";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import moment from "moment";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@material-ui/styles";
import Spinner from "../../../components/src/Spinner.web";
import RepeatingtasksController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./RepeatingtasksController.web";
// Customizable Area End

export default class Repeatingtasks extends RepeatingtasksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  taskSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("*Title is required!"),
      description: Yup.string().required("*Description is required!"),
      category: Yup.string().required("*Category is required!"),
      subCategory: Yup.string().required("*Sub category is required!"),
      technician: Yup.string().required("*Technician is required!"),
      frequency: Yup.string().required("*Frequency is required!"),
      startDate: Yup.string().required("*Start date is required!"),
      endDate: Yup.string().required("*End date is required!"),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  getStatusStyle = (status: string) => {
    if (status === "pending") {
      return webStyle.pending;
    } else if (status === "on_going") {
      return webStyle.ongoing;
    } else {
      return webStyle.completed;
    }
  };

  getPriorityStyle = (priority: string) => {
    if (priority === "high") {
      return webStyle.high;
    } else if (priority === "medium") {
      return webStyle.medium;
    } else {
      return webStyle.low;
    }
  };

  renderAddDialog = () => {
    return (
      <Dialog
        data-test-id="dialog"
        open={
          this.state.openDialogName === "Add" ||
          this.state.openDialogName === "Edit"
        }
        fullWidth={false}
        style={webStyle.mainWrapper}
        className="automated-prioritization"
        maxWidth={"sm"}
        transitionDuration={0}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent
          style={{ padding: "0px", width: "100%" }}
          data-test-id="form"
        >
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon
              data-test-id="closeBtn"
              onClick={this.onCancel}
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              mb="20px"
              fontFamily="Roboto, Helvetica, Arial, sans-serif"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
            >
              {this.state.openDialogName + " task"}
            </Box>
            <Formik
              data-test-id="formik"
              initialValues={{
                title: this.state.title,
                description: this.state.description,
                category: this.state.category,
                subCategory: this.state.subCategory,
                technician: this.state.technician,
                frequency: this.state.frequency,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
              }}
              validationSchema={this.taskSchema}
              onSubmit={(values) => {
                this.state.openDialogName == "Add"
                  ? this.onAddTask()
                  : this.onEditTask();
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Title
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <TextField
                        data-test-id="title"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "0px",
                          },
                        }}
                        placeholder="Title"
                        value={this.state.title}
                        onChange={(event) => {
                          this.onValueChange("title", event.target.value);
                          setFieldValue("title", event.target.value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "title")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Description
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <TextField
                        data-test-id="description"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "0px",
                          },
                        }}
                        placeholder="Description"
                        value={this.state.description}
                        onChange={(event) => {
                          this.onValueChange("description", event.target.value);
                          setFieldValue("description", event.target.value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "description")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Category
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Select
                        data-test-id="category"
                        classNamePrefix="react-select"
                        placeholder="Category"
                        isSearchable={false}
                        value={
                          this.state.categoryList.filter(
                            (option: Dropdown) =>
                              option.value === this.state.category
                          )[0]
                        }
                        options={this.state.categoryList}
                        onChange={(event) => {
                          const value = this.getValue(event);
                          this.onValueChange("category", value);
                          setFieldValue("category", value);
                          setFieldValue("subCategory", "");
                          this.getSubCategoryList(value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "category")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Sub category
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Select
                        data-test-id="subCategory"
                        classNamePrefix="react-select"
                        placeholder="Sub category"
                        isSearchable={false}
                        value={
                          this.state.subCategoryList.filter(
                            (option: Dropdown) =>
                              option.value === this.state.subCategory
                          )[0] ?? null
                        }
                        options={this.state.subCategoryList}
                        onChange={(event) => {
                          const value = this.getValue(event);
                          this.onValueChange("subCategory", value);
                          setFieldValue("subCategory", value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "subCategory")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Technician
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Select
                        data-test-id="technician"
                        classNamePrefix="react-select"
                        placeholder="Technician"
                        isSearchable={false}
                        value={
                          this.state.technicianList.filter(
                            (option: Dropdown) =>
                              option.value === this.state.technician
                          )[0]
                        }
                        options={this.state.technicianList}
                        onChange={(event) => {
                          const value = this.getValue(event);
                          this.onValueChange("technician", value);
                          setFieldValue("technician", value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "technician")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Frequency
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <Select
                        isDisabled={this.state.openDialogName === "Edit"}
                        data-test-id="frequency"
                        classNamePrefix="react-select"
                        placeholder="Frequency"
                        isSearchable={false}
                        value={
                          frequencyList.filter(
                            (option: Dropdown) =>
                              option.value === this.state.frequency
                          )[0]
                        }
                        options={frequencyList}
                        onChange={(event) => {
                          const value = this.getValue(event);
                          this.onValueChange("frequency", value);
                          setFieldValue("frequency", value);
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "frequency")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        Start date
                        <span style={{ color: "red" }}>*</span>
                        <DatePicker
                          disabled={this.state.openDialogName === "Edit"}
                          data-test-id="startDate"
                          selected={
                            this.state.startDate
                              ? new Date(this.state.startDate)
                              : null
                          }
                          onChange={(date) => {
                            const value = date ? date.toISOString() : "";
                            setFieldValue("startDate", value);
                            this.onValueChange("startDate", value);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                        />
                      </div>
                      {this.getErrorMessage(touched, errors, "startDate")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                        End date
                        <span style={{ color: "red" }}>*</span>
                        <DatePicker
                          disabled={this.state.openDialogName === "Edit"}
                          data-test-id="endDate"
                          selected={
                            this.state.endDate
                              ? new Date(this.state.endDate)
                              : null
                          }
                          onChange={(date) => {
                            const value = date ? date.toISOString() : "";
                            setFieldValue("endDate", value);
                            this.onValueChange("endDate", value);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                        />
                      </div>
                      {this.getErrorMessage(touched, errors, "endDate")}
                    </Grid>
                  </Grid>
                  <Box mt="30px" display="flex">
                    <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
                    <SubmitButton type="submit">
                      {this.state.openDialogName}
                    </SubmitButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  renderDeleteDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Delete"}
        fullWidth={false}
        scroll="body"
        style={webStyle.mainWrapper}
        maxWidth={"sm"}
        transitionDuration={0}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
          <Box
            style={{
              paddingTop: "0px !important",
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={this.onCancel}
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
          >
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              mb="20px"
            >
              Delete task
            </Box>
            <Box textAlign="center" fontSize="18px">
              Are you sure you want to delete the task?
            </Box>
            <Box mt="30px" display="flex">
              <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
              <DeleteButton
                data-test-id="deleteTaskBtn"
                onClick={this.onDeleteTask}
              >
                Delete
              </DeleteButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box margin="35px 30px" sx={webStyle.mainWrapper}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"
          mb="40px"
        >
          <Box>
            <Box sx={webStyle.mainTitle}>Supervisor Dashboard</Box>
            <Box color="#1b3c69" fontSize={{ xs: "16px", sm: "20px" }}>
              Task details
            </Box>
          </Box>
          <Box mt={{ xs: "20px", sm: "0px" }}>
            <Button
              data-test-id="addTaskBtn"
              onClick={this.onAddBtnClicked}
              style={{
                ...webStyle.buttonStyle,
                marginRight: "10px",
                textTransform: "none",
              }}
            >
              Add task
            </Button>
            <Button
              data-test-id="logoutBtn"
              onClick={this.handleLogout}
              style={{ ...webStyle.buttonStyle, textTransform: "none" }}
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Box mt="60px">
          <Box>
            <Grid container>
              <Grid item xs={12} style={{ overflow: "auto" }}>
                <Table
                  aria-label="simple table"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                    borderCollapse: "separate",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={webStyle.tableData}>
                        Title
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Description
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Status
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Priority
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Technician
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Frequency
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Date
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Start date
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        End date
                      </TableCell>
                      <TableCell align="left" style={webStyle.tableData}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data.length > 0 ? (
                      <>
                        {this.state.data.map((item: Data, index: number) => {
                          const statusStyle = this.getStatusStyle(
                            item.status.toLocaleLowerCase()
                          );
                          const priorityStyle = this.getPriorityStyle(
                            item.priority.toLocaleLowerCase()
                          );

                          return (
                            <TableRow key={`${index + 1}`}>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {item.title}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {item.description}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                <div style={statusStyle}>{item.status}</div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                <div style={priorityStyle}>{item.priority}</div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {item.technicianName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {item.frequency}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {moment(item.date).format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {moment(item.startDate).format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                {moment(item.endDate).format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={webStyle.tableData}
                              >
                                <Box whiteSpace="noWrap">
                                  <EditIcon
                                    data-test-id="editBtn"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => this.onEditBtnClicked(item)}
                                  />
                                  <DeleteIcon
                                    data-test-id="deleteBtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.onDelete(item.filterId, item.subTaskId)
                                    }
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          align="center"
                          style={webStyle.noRecord}
                        >
                          No record's found!
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {this.state.data.length > 0 && (
                  <div>
                    <Pagination
                      data-test-id="pagination"
                      style={webStyle.pagination}
                      page={this.state.page + 1}
                      count={Math.ceil(
                        this.state.dataLength / this.state.rowsPerPage
                      )}
                      onChange={(event: unknown, newPage: number) => {
                        this.onChangeHandler(newPage - 1);
                      }}
                      showFirstButton
                      showLastButton
                      variant="outlined"
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        {this.renderAddDialog()}
        {this.renderDeleteDialog()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const frequencyList = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Bi-monthly", value: "bi_monthly" },
  { label: "Yearly", value: "yearly" }
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const DeleteButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "18px",
  padding: "6px 20px",
  "&:hover": {
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  border: "1px solid rgb(98, 0, 238)",
  borderRadius: "8px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  marginRight: "15px",
  textTransform: "none",
  "&:hover": {
    color: "rgb(98, 0, 238)",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px"
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  high: {
    backgroundColor: "rgba(255,0,0,0.2)",
    border: "1px solid rgba(255,0,0,1)",
    padding: "5px 10px",
    color: "rgba(255, 0, 0, 1)",
    borderRadius: "20px",
    width: "90px",
    textAlign: "center" as const
  },
  medium: {
    backgroundColor: "rgba(255,255,0,0.2)",
    color: "rgba(167,255,0,1)",
    padding: "5px 10px",
    borderRadius: "20px",
    border: "1px solid rgba(255,255,0,1)",
    width: "90px",
    textAlign: "center" as const
  },
  low: {
    backgroundColor: "rgba(0,128,0,0.2)",
    color: "rgba(0,128,0,1)",
    padding: "5px 10px",
    borderRadius: "20px",
    border: "1px solid rgba(0,128,0,1)",
    width: "90px",
    textAlign: "center" as const
  },
  pending: {
    backgroundColor: "rgba(247,198,0, 0.2)",
    color: "rgba(247,198,0, 1)",
    border: "1px solid rgba(247,198,0, 1)",
    padding: "5px 10px",
    borderRadius: "20px",
    width: "90px",
    textAlign: "center" as const
  },
  ongoing: {
    border: "1px solid rgba(21,135,247, 1)",
    padding: "5px 10px",
    backgroundColor: "rgba(21,135,247, 0.2)",
    borderRadius: "20px",
    color: "rgba(21,135,247, 1)",
    width: "90px",
    textAlign: "center" as const
  },
  completed: {
    borderRadius: "20px",
    backgroundColor: "rgba(14,146,69,0.2)",
    color: "rgba(14,146,69,1)",
    border: "1px solid rgba(14,146,69,1)",
    padding: "5px 10px",
    width: "90px",
    textAlign: "center" as const
  },
  pagination: {
    marginTop: "15px",
    justifyContent: "end",
    display: "flex",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
};
// Customizable Area End
