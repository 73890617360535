import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Select, { ValueType } from "react-select";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import moment from "moment";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@material-ui/styles";
import Spinner from "../../../components/src/Spinner.web";
import AutomatedprioritisationController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./AutomatedprioritisationController.web";
// Customizable Area End

export default class Automatedprioritisation extends AutomatedprioritisationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  taskSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("*Title is required!"),
      description: Yup.string().required("*Description is required!"),
      startDate: Yup.string().required("*Start date is required!"),
      frequency: Yup.string().required("*Frequency is required!"),
      category: Yup.string().required("*Category is required!"),
      subCategory: Yup.string().required("*Sub category is required!"),
      technician: Yup.string().required("*Technician is required!"),
      endDate: Yup.string().required("*End date is required!"),
    });
  };

  getErrorMessage = (touched: FormikTouched<Touched>, errors: FormikErrors<Error>, value: string) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  isOpen = () => {
    return this.state.openDialogName === "Add" || this.state.openDialogName === "Edit";
  }

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  }

  getStatusStyle = (status: string) => {
    if (status === "pending") {
      return webStyle.pending;
    } else if (status === "on going") {
      return webStyle.ongoing;
    } else {
      return webStyle.completed;
    }
  };

  getPriorityStyle = (priority: string) => {
    if (priority === "high") {
      return webStyle.high;
    } else if (priority === "medium") {
      return webStyle.medium;
    } else {
      return webStyle.low;
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box margin="35px 30px" sx={webStyle.mainWrapper}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box
          display={{ xs: "block", sm: "flex" }}
          justifyContent="space-between"
          mb="40px"
        >
          <Box>
            <Box sx={webStyle.mainTitle}>
              Supervisor Dashboard
            </Box>
            <Box color="#1b3c69" fontSize={{ xs: "16px", sm: "20px" }}>
              Task details
            </Box>
          </Box>
          <Box mt={{ xs: "20px", sm: "0px" }}>
            <Button
              data-test-id="addTaskBtn"
              onClick={this.onAddBtnClicked}
              style={{ ...webStyle.buttonStyle, marginRight: "10px", textTransform: "none" }}
            >
              Add task
            </Button>
            <Button
              data-test-id="logoutBtn"
              style={{ ...webStyle.buttonStyle, textTransform: "none" }}
              onClick={this.handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Box mt="60px">
          <Box>
            <Grid container>
              <Grid item xs={12} style={{ overflow: "auto" }}>
                <Table
                  style={{
                    borderCollapse: "separate",
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={webStyle.tableData}>Title</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Description</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Status</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Priority</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Technician</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Frequency</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Date</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Start date</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>End date</TableCell>
                      <TableCell align="left" style={webStyle.tableData}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data.length > 0 ? (
                      <>
                        {this.state.data.map((item: Data, index: number) => {
                          const statusStyle = this.getStatusStyle(item.status.toLocaleLowerCase());
                          const priorityStyle = this.getPriorityStyle(item.priority.toLocaleLowerCase());

                          return (
                            <TableRow key={`${index + 1}`}>
                              <TableCell align="left" style={webStyle.tableData}>{item.title}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>{item.description}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>
                                <div style={statusStyle}>{item.status}</div>
                              </TableCell>
                              <TableCell align="left" style={webStyle.tableData}>
                                <div style={priorityStyle}>{item.priority}</div>
                              </TableCell>
                              <TableCell align="left" style={webStyle.tableData}>{item.technicianName}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>{item.frequency}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>{moment(item.date).format('DD MMM YYYY')}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>{moment(item.startDate).format('DD MMM YYYY')}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>{moment(item.endDate).format('DD MMM YYYY')}</TableCell>
                              <TableCell align="left" style={webStyle.tableData}>
                                <Box whiteSpace="noWrap">
                                  <EditIcon
                                    data-test-id="editBtn"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => this.onEditBtnClicked(item)}
                                  />
                                  <DeleteIcon
                                    data-test-id="deleteBtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onDelete(item.id, item.subTaskId)}
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        }
                        )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} align="center" style={webStyle.noRecord}>
                          No record's found!
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {this.state.data.length > 0 && (
                  <div>
                    <Pagination
                      data-test-id="pagination"
                      style={webStyle.pagination}
                      page={this.state.page + 1}
                      count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                      onChange={(event: unknown, newPage: number) => {
                        this.onChangeHandler(newPage - 1);
                      }}
                      showFirstButton
                      showLastButton
                      variant="outlined"
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog
          data-test-id="dialog"
          open={this.isOpen()}
          fullWidth={false}
          transitionDuration={0}
          style={webStyle.mainWrapper}
          className="automated-prioritization"
          aria-labelledby="scroll-dialog-title"
          maxWidth={"sm"}
          scroll="body"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent style={{ padding: "0px", width: "100%" }} data-test-id="form">
            <Box style={{ margin: "10px 10px 0px 0px", textAlign: "right" }}>
              <CloseIcon
                data-test-id="closeBtn"
                onClick={this.onCancel}
                style={{
                  color: "#787878",
                  cursor: "pointer",
                  fontSize: "35px",
                }}
              />
            </Box>
            <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
              <Box
                id="modal-title"
                mb="20px"
                fontWeight="bolder"
                fontFamily="Roboto, Helvetica, Arial, sans-serif"
                fontSize={{ xs: "20px", sm: "30px" }}
              >
                {this.state.openDialogName + " task"}
              </Box>
              <Formik
                initialValues={{
                  title: this.state.title,
                  description: this.state.description,
                  technician: this.state.technician,
                  subCategory: this.state.subCategory,
                  frequency: this.state.frequency,
                  category: this.state.category,
                  startDate: this.state.startDate,
                  endDate: this.state.endDate
                }}
                data-test-id="formik"
                validationSchema={this.taskSchema}
                onSubmit={(values) => { this.onSubmit()}}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Title
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          data-test-id="title"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px"
                            }
                          }}
                          placeholder="Title"
                          value={this.state.title}
                          onChange={(event) => {
                            this.onValueChange("title", event.target.value);
                            setFieldValue("title", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "title")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Description
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          data-test-id="description"
                          placeholder="Description"
                          value={this.state.description}
                          inputProps={{
                            style: {
                              height: "0px"
                            }
                          }}
                          onChange={(event) => {
                            this.onValueChange(
                              "description",
                              event.target.value
                            );
                            setFieldValue("description", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "description")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Category
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <Select
                          data-test-id="category"
                          classNamePrefix="react-select"
                          isSearchable={false}
                          placeholder="Category"
                          options={this.state.categoryList}
                          value={
                            this.state.categoryList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.category
                            )[0]
                          }
                          onChange={(event) => {
                            const value = this.getValue(event);
                            this.onValueChange("category", value);
                            setFieldValue("category", value);
                            setFieldValue("subCategory", "");
                            this.getSubCategoryList(value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "category")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Sub category
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <Select
                          data-test-id="subCategory"
                          placeholder="Sub category"
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={this.state.subCategoryList}
                          value={
                            this.state.subCategoryList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.subCategory
                            )[0] ?? null
                          }
                          onChange={(event) => {
                            const value = this.getValue(event);
                            this.onValueChange("subCategory", value);
                            setFieldValue("subCategory", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "subCategory")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Technician
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <Select
                          data-test-id="technician"
                          isSearchable={false}
                          classNamePrefix="react-select"
                          placeholder="Technician"
                          options={this.state.technicianList}
                          value={
                            this.state.technicianList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.technician
                            )[0]
                          }
                          onChange={(event) => {
                            const value = this.getValue(event);
                            this.onValueChange("technician", value);
                            setFieldValue("technician", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "technician")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Frequency
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <Select
                          isDisabled={this.state.openDialogName === "Edit"}
                          isSearchable={false}
                          data-test-id="frequency"
                          classNamePrefix="react-select"
                          placeholder="Frequency"
                          options={frequencyList}
                          value={
                            frequencyList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.frequency
                            )[0]
                          }
                          onChange={(event) => {
                            const value = this.getValue(event);
                            this.onValueChange("frequency", value);
                            setFieldValue("frequency", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "frequency")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          Start date
                          <span style={{ color: "red" }}>*</span>
                          <DatePicker
                            disabled={this.state.openDialogName === "Edit"}
                            selected={this.state.startDate ? new Date(this.state.startDate) : null}
                            data-test-id="startDate"
                            onChange={(date) => {
                              const value = date ? date.toISOString() : "";
                              setFieldValue("startDate", value);
                              this.onValueChange("startDate", value);
                            }}
                            peekNextMonth
                            showYearDropdown
                            minDate={new Date()}
                            placeholderText="DD/MM/YYYY"
                            showMonthDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        {this.getErrorMessage(touched, errors, "startDate")}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ marginBottom: "5px", fontSize: "16px" }}>
                          End date
                          <span style={{ color: "red" }}>*</span>
                          <DatePicker
                            disabled={this.state.openDialogName === "Edit"}
                            selected={this.state.endDate ? new Date(this.state.endDate) : null}
                            data-test-id="endDate"
                            onChange={(date) => {
                              const value = date ? date.toISOString() : "";
                              setFieldValue("endDate", value);
                              this.onValueChange("endDate", value);
                            }}
                            peekNextMonth
                            dropdownMode="select"
                            showMonthDropdown
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            placeholderText="DD/MM/YYYY"
                          />
                        </div>
                        {this.getErrorMessage(touched, errors, "endDate")}
                      </Grid>
                    </Grid>
                    <Box mt="30px" display="flex">
                      <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
                      <SubmitButton type="submit">{this.state.openDialogName}</SubmitButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openDialogName === "Delete"}
          fullWidth={false}
          scroll="body"
          transitionDuration={0}
          style={webStyle.mainWrapper}
          maxWidth={"sm"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
            <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
              <CloseIcon
                onClick={this.onCancel}
                style={{
                  cursor: "pointer",
                  color: "#787878",
                  fontSize: "35px",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
              <Box
                fontWeight="bolder"
                id="modal-title"
                mb="20px"
                fontSize={{ xs: "20px", sm: "30px" }}
              >
                Delete task
              </Box>
              <Box textAlign="center" fontSize="18px">
                Are you sure you want to delete the task?
              </Box>
              <Box mt="30px" display="flex">
                <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
                <DeleteButton data-test-id="deleteTaskBtn" onClick={this.onDeleteTask}>Delete</DeleteButton>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  padding: "6px 31px",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const frequencyList = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Bi-monthly", value: "bi_monthly" },
  { label: "Yearly", value: "yearly" }
];

const DeleteButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  borderRadius: "10px",
  color: "white",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 20px",
  textTransform: "none",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  border: "1px solid rgb(98, 0, 238)",
  padding: "6px 17px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    fontSize: "30px",
    color: "#1b3c69",
  },
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  medium: {
    backgroundColor: "rgba(255,255,0,0.2)",
    borderRadius: "20px",
    color: "rgba(167,255,0,1)",
    padding: "5px 10px",
    border: "1px solid rgba(255,255,0,1)",
    textAlign: "center" as const,
    width: "90px",
  },
  high: {
    backgroundColor: "rgba(255,0,0,0.2)",
    border: "1px solid rgba(255,0,0,1)",
    padding: "5px 10px",
    textAlign: "center" as const,
    color: "rgba(255, 0, 0, 1)",
    borderRadius: "20px",
    width: "90px",
  },
  low: {
    backgroundColor: "rgba(0,128,0,0.2)",
    borderRadius: "20px",
    color: "rgba(0,128,0,1)",
    width: "90px",
    padding: "5px 10px",
    border: "1px solid rgba(0,128,0,1)",
    textAlign: "center" as const
  },
  pending: {
    width: "90px",
    color: "rgba(247,198,0, 1)",
    border: "1px solid rgba(247,198,0, 1)",
    padding: "5px 10px",
    borderRadius: "20px",
    backgroundColor: "rgba(247,198,0, 0.2)",
    textAlign: "center" as const
  },
  completed: {
    color: "rgba(14,146,69,1)",
    borderRadius: "20px",
    backgroundColor: "rgba(14,146,69,0.2)",
    border: "1px solid rgba(14,146,69,1)",
    padding: "5px 10px",
    width: "90px",
    textAlign: "center" as const
  },
  ongoing: {
    border: "1px solid rgba(21,135,247, 1)",
    backgroundColor: "rgba(21,135,247, 0.2)",
    borderRadius: "20px",
    color: "rgba(21,135,247, 1)",
    padding: "5px 10px",
    width: "90px",
    textAlign: "center" as const
  },
  pagination: {
    justifyContent: "end",
    display: "flex",
    marginTop: "15px",
  },
  buttonStyle: {
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "6px 17px",
    fontSize: "18px",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
};
// Customizable Area End
