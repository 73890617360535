import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: { navigate: (path: string) => void };
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  title: boolean;
  description: boolean;
  category: boolean;
  subCategory: boolean;
  startDate: boolean;
  endDate: boolean;
  technician: boolean;
  frequency: boolean;
}

export interface Error {
  title: string;
  description: string;
  category: string;
  subCategory: string;
  startDate: string;
  endDate: string;
  technician: string;
  frequency: string;
}

export interface PriorityOrder {
  high: number;
  medium: number;
  low: number;
}

export interface SubTask {
  id: string;
  attributes: {
    status: string,
    priority: string;
    date: string;
  }
}

export interface ResponseJson {
  id: string;
  attributes: {
    title: string;
    description: string;
    service_category_id: number;
    service_sub_category_id: number;
    technician_id: number;
    start_date: string;
    end_date: string;
    technician: string;
    frequency: string;
    task_date_statuses: {
      data: SubTask[];
    };
  }
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  filterId: string;
  subTaskId: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  categoryId: string;
  categoryName: string;
  subCategoryId: string;
  subCategoryName: string;
  date: string;
  technicianId: string;
  technicianName: string;
  frequency: string;
  startDate: string;
  endDate: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  taskId: string;
  subTaskId: string;
  data: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  filterData: Data[];
  openDialogName: string;
  title: string;
  description: string;
  category: string;
  subCategory: string;
  startDate: string;
  endDate: string;
  technician: string;
  frequency: string;
  categoryList: Dropdown[];
  subCategoryList: Dropdown[];
  technicianList: Dropdown[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class RepeatingtasksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTaskListApiCallId: string = "";
  getCategoryListApiCallId: string = "";
  getSubCategoryListApiCallId: string = "";
  getTechnicianListApiCallId: string = "";
  addTaskApiCallId: string = "";
  deleteTaskApiCallId: string = "";
  editTaskApiCallId: string = "";
  taskReminderApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      taskId: "",
      subTaskId: "",
      data: [],
      filterData: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 5,
      openDialogName: "",
      title: "",
      description: "",
      category: "",
      subCategory: "",
      startDate: "",
      endDate: "",
      technician: "",
      frequency: "",
      categoryList: [],
      subCategoryList: [],
      technicianList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (apiRequestCallId == this.getTaskListApiCallId) {
          const filterData: Data[] = [];
          responseJson.data.forEach((item: ResponseJson) => {
            item.attributes.task_date_statuses.data.forEach((subTask: SubTask) => {
              filterData.push({
                filterId: item.id,
                subTaskId: subTask.id,
                title: item.attributes.title,
                description: item.attributes.description,
                status: subTask.attributes.status,
                priority: subTask.attributes.priority,
                categoryId: item.attributes.service_category_id.toString(),
                categoryName: "category",
                subCategoryId: item.attributes.service_sub_category_id.toString(),
                subCategoryName: "sub category",
                technicianId: item.attributes.technician_id.toString(),
                technicianName: item.attributes.technician,
                frequency: item.attributes.frequency,
                date: subTask.attributes.date,
                startDate: item.attributes.start_date,
                endDate: item.attributes.end_date
              });
            });
          });

          filterData.sort((item1: Data, item2: Data) => {
            const priorityOrder: PriorityOrder = {
              "high": 3,
              "medium": 2,
              "low": 1
            };

            return priorityOrder[item2.priority as keyof PriorityOrder] - priorityOrder[item1.priority as keyof PriorityOrder];
          });
          this.setState({ filterData }, () => {
            this.onChangeHandler(0);
          });
        } else if (apiRequestCallId == this.getCategoryListApiCallId) {
          const categoryList = responseJson.map((item: { id: number; category_name: string; }) => {
            return { label: item.category_name, value: item.id.toString() };
          });
          this.setState({ categoryList });
        } else if (apiRequestCallId == this.getSubCategoryListApiCallId) {
          const subCategoryList = responseJson.map((item: { id: number; name: string }) => {
            return { label: item.name, value: item.id.toString() };
          });
          this.setState({ subCategoryList, loading: false });
        } else if (apiRequestCallId == this.getTechnicianListApiCallId) {
          const technicianList = responseJson.data.map((item: { attributes: { first_name: string; last_name: string }; id: string; }) => {
            return { label: item.attributes.first_name + " " + item.attributes.last_name, value: item.id };
          });
          this.setState({ technicianList, loading: false });
        } else if (apiRequestCallId == this.addTaskApiCallId) {
          toast.success("Task details added successfully!");
          this.setState({ openDialogName: "" });
          this.onCancel();
          this.getTaskList();
        } else if (apiRequestCallId == this.editTaskApiCallId) {
          toast.success("Task details updated successfully!");
          this.setState({ openDialogName: "" });
          this.onCancel();
          this.getTaskList();
        } else if (apiRequestCallId == this.deleteTaskApiCallId) {
          toast.success("Task details deleted successfully!");
          this.setState({ openDialogName: "" });
          this.onCancel();
          this.getTaskList();
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getTaskList();
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onAddBtnClicked = () => {
    this.getCategoryList();
    this.getTechnicianList();
    this.setState({ openDialogName: "Add" });
  }

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let data = filterData;
    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, loading: false });
  };

  onEditBtnClicked = (item: Data) => {
    this.getCategoryList();
    this.getSubCategoryList(item.categoryId);
    this.getTechnicianList();
    this.setState({
      taskId: item.filterId,
      title: item.title,
      description: item.description,
      category: item.categoryId,
      subCategory: item.subCategoryId,
      technician: item.technicianId,
      frequency: item.frequency,
      startDate: item.startDate,
      endDate: item.endDate,
      openDialogName: "Edit"
    });
  }

  onDelete = (taskId: string, subTaskId: string) => {
    this.setState({ taskId, subTaskId, openDialogName: "Delete" });
  }

  onCancel = () => {
    this.setState({
      taskId: "",
      title: "",
      description: "",
      category: "",
      subCategory: "",
      technician: "",
      frequency: "",
      startDate: "",
      endDate: "",
      openDialogName: "",
      subCategoryList: []
    });
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.navigation.navigate('Login');
  }

  getTaskList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getTaskListApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategoryList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getCategoryListApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoryListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSubCategoryList = (categoryId: string) => {
    this.setState({ loading: true, subCategory: "" });

    const header = {
      "Content-Type": configJSON.getSubCategoryListApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubCategoryListApiEndPoint}/${categoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubCategoryListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTechnicianList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getTechnicianListApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTechnicianListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTechnicianListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTechnicianListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.addTaskApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const httpBody = {
      "data": {
        "attributes": {
          "title": this.state.title,
          "description": this.state.description,
          "service_category_id": this.state.category,
          "service_sub_category_id": this.state.subCategory,
          "technician_id": this.state.technician,
          "frequency": this.state.frequency,
          "start_date": this.state.startDate,
          "end_date": this.state.endDate
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTaskApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addTaskApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.editTaskApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const httpBody = {
      "data": {
        "attributes": {
          "title": this.state.title,
          "description": this.state.description,
          "service_category_id": this.state.category,
          "service_sub_category_id": this.state.subCategory,
          "technician_id": this.state.technician,
          "frequency": this.state.frequency,
          "start_date": this.state.startDate,
          "end_date": this.state.endDate
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editTaskApiEndPoint}/${this.state.taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editTaskApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onDeleteTask = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.deleteTaskApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const httpBody = {
      "data": {
        "attributes": {
          "task_date_statuses_attributes": [{
            "id": this.state.subTaskId,
            "_destroy": true
          }]
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteTaskApiEndPoint}/${this.state.taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteTaskApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
