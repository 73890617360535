Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.validationApiMethodType = "GET";
exports.exampleApiContentType = "application/json";
exports.labelBodyText = "automatedprioritisation Body";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automatedprioritisation";

exports.btnExampleTitle = "CLICK ME";
exports.getTechnicianTaskListApiContentType = "application/json";
exports.getTechnicianTaskListApiMethod = "GET";
exports.getTechnicianTaskListApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/all_task";

exports.getSubCategoryListApiContentType = "application/json";
exports.getSubCategoryListApiMethod = "GET";
exports.getSubCategoryListApiEndPoint = "/bx_block_automatedprioritisation/service_sub_categories";

exports.changeTaskApiMethod = "GET";
exports.changeTaskApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/starting_task";

exports.completedTaskApiMethod = "GET";
exports.completedTaskEndPoint = "/bx_block_automatedprioritisation/priority_tasks/completed_task";

exports.getCategoryListApiMethod = "GET";
exports.getCategoryListApiContentType = "application/json";
exports.getCategoryListApiEndPoint = "/bx_block_automatedprioritisation/service_categories";

exports.getTechnicianListApiContentType = "application/json";
exports.getTechnicianListApiMethod = "GET";
exports.getTechnicianListApiEndPoint = "/account_block/accounts?type=technician";

exports.getTaskListApiContentType = "application/json";
exports.getTaskListApiMethod = "GET";
exports.getTaskListApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/all_task";

exports.addTaskApiMethod = "POST";
exports.addTaskApiContentType = "application/json";
exports.addTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.editTaskApiMethod = "PUT";
exports.editTaskApiContentType = "application/json";
exports.editTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.deleteTaskApiMethod = "PUT";
exports.deleteTaskApiContentType = "application/json";
exports.deleteTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";
// Customizable Area End