import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import LoginController, { Props } from "./LoginController.web";
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <div className="Toastify"></div>
      <Container maxWidth={"sm"}>
        <Box sx={webStyle.mainWrapper}>
          <Formik
            initialValues={{
              email: "",
              password: "",
              type: "Technician",
            }}
            data-test-id="formik"
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email format")
                .required("Email is required"),
              type: Yup.string().required("Type is required"),
              password: Yup.string()
                .nullable()
                .required("Password is required"),
            })}
            onSubmit={() => {
              this.handleLogin();
            }}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box sx={webStyle.formWrapper}>
                  <Typography align="center" variant="h6">
                    Login
                  </Typography>
                  <Box sx={webStyle.inputWrapper}>
                    <InputLabel style={webStyle.label}>Email</InputLabel>
                    <CustomInputBase
                      id="email"
                      data-test-id={"email"}
                      name="email"
                      value={props.values.email}
                      onChange={(event) => {
                        props.setFieldValue("email", event.target.value);
                        this.onValueChange("email", event.target.value);
                      }}
                      fullWidth={true}
                      onBlur={props.handleBlur}
                      variant="outlined"
                      type="text"
                      placeholder="Enter Email"
                    />
                    {this.getErrorMessage(props.touched, props.errors, "email")}
                  </Box>
                  <Box sx={webStyle.inputWrapper}>
                    <InputLabel style={webStyle.label}>Password</InputLabel>
                    <CustomInputBase
                      data-test-id={"password"}
                      id="password"
                      name="password"
                      value={props.values.password}
                      onChange={(event) => {
                        props.setFieldValue("password", event.target.value);
                        this.onValueChange("password", event.target.value);
                      }}
                      onBlur={props.handleBlur}
                      type={"password"}
                      variant="outlined"
                      placeholder="Enter Password"
                      fullWidth={true}
                    />
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "password"
                    )}
                  </Box>
                
                  <Button
                    type="submit"
                    data-test-id="LoginBtn"
                    style={webStyle.buttonStyle}
                  >
                    Log in
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomInputBase = styled(TextField)({
  borderRadius: "5px",
  marginBottom: "12px",
  minHeight: "50px",
  marginTop: "8px",
  "& .MuiInputBase-input": {
    padding: "15px",
    "&::placeholder": {
      fontSize: "14px",
    },
  },
  "& .Mui-focused": {
    border: "1px solid #d6d6d6",
  },
  "& input": {
    fontSize: "14px",
  },
});
const webStyle = {
  label: {
    fontSize: "14px",
    marginTop: "8px",
    fontWeight: 300,
  },
  inputWrapper: {
    position: "relative",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    height: "100vh",
  },
  buttonStyle: {
    width: "100%",
    marginTop: "25px",
    border: "none",
    height: "45px",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
  formWrapper: {
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    width: "100%",
    padding: "20px",
  },
};
// Customizable Area End
